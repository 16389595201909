import React, { memo } from 'react'
import AutoSizer from 'react-virtualized-auto-sizer'
import {
  areEqual,
  FixedSizeGrid as Grid,
  GridChildComponentProps,
} from 'react-window'
import styled from 'styled-components'
import { Nft, NftCollection } from '../../../store/web3/collection.state'
import { DisplayNft } from './DisplayNft'
import './styles.css'
import {
  filterOwned,
  filterSecretRares,
  filterTrash,
  sortNfts,
  SortType,
} from './util'
import { useTranslation } from 'react-i18next'
import { H1 } from '../../../components'

const GUTTER_SIZE = 400
const PADDING_SIZE = 50

interface Props {
  collection: NftCollection
  showOwned: boolean
  showBindingFailed: boolean
}

export const CollectionDisplay: React.FC<Props> = ({
  collection,
  showOwned,
  showBindingFailed,
}) => {
  const { t } = useTranslation()

  let { nfts } = collection
  if (!showBindingFailed) {
    nfts = filterTrash(nfts)
  }
  nfts = filterSecretRares(nfts)
  if (showOwned) {
    nfts = filterOwned(nfts)
  }

  nfts = sortNfts(nfts, SortType.BY_RARITY, 'desc')

  const { length } = nfts

  return (
    <CollectionContainer>
      {length ? (
        <AutoSizer>
          {({ height, width }) => {
            const NFT_HEIGHT = 533
            const NFT_WIDTH = 300
            const nftsPerRow = Math.max(
              Math.round((width / NFT_WIDTH) * 0.75),
              1,
            )
            const columnCount = nftsPerRow
            const rowCount = Math.ceil(length / nftsPerRow)
            const columnWidth = Math.max((width / columnCount) * 1, NFT_WIDTH)

            return (
              <Grid
                itemData={{ nfts, nftsPerRow }}
                columnCount={columnCount}
                columnWidth={columnWidth}
                height={height}
                rowCount={rowCount}
                rowHeight={NFT_HEIGHT * 1.1}
                width={width}
                className="Grid"
              >
                {ItemRenderer}
              </Grid>
            )
          }}
        </AutoSizer>
      ) : (
        <NoCardsContainer>
          <H1>{t('noBindersOwned')}</H1>
          <HeroButton
            href="https://dokidoki.com/gachapon/0xeFd7b1DC887b79568A59c39e6d35B6F2F2D18f02"
            target="_blank"
            rel="noreferrer"
          >
            {t('rollBinder')}
          </HeroButton>
        </NoCardsContainer>
      )}
    </CollectionContainer>
  )
}
const ItemRenderer = memo(
  ({
    columnIndex,
    rowIndex,
    data: { nfts, nftsPerRow },
    style,
  }: GridChildComponentProps<{ nfts: Nft[]; nftsPerRow: number }>) => {
    const index = columnIndex + rowIndex * nftsPerRow
    const nft = nfts[index]
    if (!nft) {
      return null
    }
    return (
      <div
        style={{
          ...style,
          left: Number(style?.left ?? 0) + GUTTER_SIZE / 8,
          top: Number(style?.top ?? 0) + GUTTER_SIZE / 6,
          height: Number(style?.height ?? 0) + PADDING_SIZE * 2,
        }}
      >
        <DisplayNft nft={nft} />
      </div>
    )
  },
  areEqual,
)

const HeroButton = styled.a`
  color: black;
  line-height: 150%;
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding-right: 2.5rem;
  padding-left: 2.5rem;
  border-radius: 18px;
  font-weight: 600;
  font-family: 'Open Sans';
  font-size: 1.25rem;
  box-sizing: border-box;
  z-index: 1;
  letter-spacing: 1px;
  text-decoration: none;
  overflow: hidden;
  transition: 0.5s;
  backdrop-filter: blur(15px);
  border-radius: 3.5rem / 100%;

  background: rgb(0, 255, 194);
  background: linear-gradient(
    90deg,
    rgba(0, 255, 194, 1) 0%,
    rgba(0, 234, 223, 1) 100%
  );

  &:hover {
    box-shadow: 0 0 5px #00ffc2, 0 0 15px #00ffc2, 0 0 30px #00ffc2,
      0 0 60px #00ffc2;
  }

  @media (min-width: 1200px) {
    padding-right: 5rem;
    padding-left: 5rem;
  }
`
const NoCardsContainer = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
`

const CollectionContainer = styled.div`
  width: 100%;
  position: relative;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 18px;
  border: 1px solid rgba(255, 255, 255, 0.18);

  @media (min-width: 250px) {
    grid-row-start: 4;
    grid-row-end: 3;
    height: 100vh;
  }

  @media (min-width: 750px) {
    grid-row-start: 3;
    grid-row-end: 4;
    height: 80vh;
  }

  @media (min-width: 1200px) {
    height: 100%;

    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 2;
    grid-row-end: 3;
  }
`
