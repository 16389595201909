import {
  Nft,
  NftType,
  OwnedKeyedNft,
} from '../../../store/web3/collection.state'

export enum SortType {
  DEFAULT,
  BY_RARITY,
  BY_AMOUNT_OWNED,
}
export const RarityToId: Record<string, number> = {
  Common: 1,
  Rare: 2,
  Mythic: 3,
  Ethereal: 4,
  Legendary: 5,
  'Secret Rare': 6,
  Secret: 6,
}

export function sortNfts(
  nfts: Nft[],
  sortType: SortType,
  order: 'asc' | 'desc',
): Nft[] {
  switch (sortType) {
    case SortType.DEFAULT: {
      return nfts
    }
    case SortType.BY_AMOUNT_OWNED: {
      const sorted = nfts.sort((a, b) => {
        if (a.type === NftType.OWNED && b.type === NftType.OWNED) {
          return a.totalBalance - b.totalBalance
        } else {
          return 0
        }
      })

      return order === 'asc' ? sorted : sorted.reverse()
    }
    case SortType.BY_RARITY: {
      const sorted = nfts.sort((a, b) => {
        return RarityToId[a.rarityType] - RarityToId[b.rarityType]
      })

      return order === 'asc' ? sorted : sorted.reverse()
    }
  }
}

export function filterSecretRares(nfts: Nft[]): Nft[] {
  return nfts.filter((n) => {
    return n.type === NftType.OWNED || !n.isSecret
  })
}

export function filterTrash(nfts: Nft[]): Nft[] {
  return nfts.filter((n) => {
    if (n.type === NftType.STATIC) {
      return true
    }

    return Number(n.rarity.poolRatio) > 0 || n.isSecret
  })
}

export function filterOwned(nfts: Nft[]): OwnedKeyedNft[] {
  return nfts.filter((n): n is OwnedKeyedNft => n.type === NftType.OWNED)
}
