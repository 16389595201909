import React from 'react'
import { CollectionDisplay } from '../../features/my-collection/CollectionDisplay'
import { Layout } from '../../features/my-collection/Layout'

export const Collection: React.FC = () => {
  return (
    <Layout
      renderWith={(collection, showOwned, showBindingFailed) => (
        <CollectionDisplay
          collection={collection}
          showOwned={showOwned}
          showBindingFailed={showBindingFailed}
        />
      )}
    />
  )
}

export default Collection
