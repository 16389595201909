import { Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import React from 'react'
import HoverVideoPlayer from 'react-hover-video-player'
import styled from 'styled-components'
import { Spinner } from '../../../components'
import { Nft, NftType } from '../../../store/web3/collection.state'
import { bn, fEth } from '../Unbind/utils'
import { NftValue } from './styles'
import './styles.css'

interface Props {
  nft: Nft
}

export const DisplayNft: React.FC<Props> = ({ nft }) => {
  const { localVideo, gatsbyPath } = nft
  const image = getImage(localVideo.videoScreenshots[0])
  if (!image) {
    console.error('No image found', nft.name)
  }
  return (
    <CollectionNft>
      <Link to={gatsbyPath}>
        <AbsoluteLink to={gatsbyPath}>
          <StyledSVG
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1 12.9999V10.9999H15.4853L12.2427 7.75724L13.6569 6.34303L19.3137 11.9999L13.6569 17.6567L12.2427 16.2425L15.4853 12.9999H1Z"
              fill="white"
            />
          </StyledSVG>
        </AbsoluteLink>
        <HoverVideoPlayer
          className={nft.type === NftType.OWNED ? 'owned' : 'not-owned'}
          pausedOverlay={
            image && (
              <GatsbyImage
                image={image}
                alt={nft.name}
                style={{ width: '298px', height: '543px', objectFit: 'cover' }}
              />
            )
          }
          loadingOverlay={<HoverSpinner />}
          key={localVideo.publicURL}
          style={{ width: '300px', height: '545px', objectFit: 'cover' }}
          videoSrc={localVideo.publicURL}
          unloadVideoOnPaused
        />
      </Link>
      <DisplayLiveNftProperties nft={nft} />
      {nft.type === NftType.OWNED && (
        <CopiesOwned>
          x{nft.balanceByLayer.L1 + nft.balanceByLayer.L2}
        </CopiesOwned>
      )}
    </CollectionNft>
  )
}

const DisplayLiveNftProperties: React.FC<{ nft: Nft }> = ({ nft }) => {
  if (nft.type === NftType.STATIC) {
    return (
      <FlexDiv>
        <h2>Loading live stats...</h2> <Spinner size={1.5} />
      </FlexDiv>
    )
  }

  const { rarity } = nft
  const nftBurnValue = fEth(bn(rarity.ethBalance).div(rarity.nftAmount), 6)
  return <NftValue>{nftBurnValue} ETH</NftValue>
}

export const HoverSpinner = styled.div`
    z-index: 10;
    width: 10rem; 
    height: 10rem;
    margin-top: 10em;
    display: inline-block;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
    border: 10px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top-color: rgba(255, 255, 255, 0.8);
    animation: 1s spin ease-in-out infinite;
    margin-bottom: 0rem;
   
  }
  @keyframes spin{
    to{
      transform: rotate(360deg);
    }
  `

const CopiesOwned = styled.div`
  z-index: 2;
  position: absolute;
  bottom: 0;
  right: -1rem;
  height: 3rem;
  width: 3rem;
  background: #000000;
  border: 2px solid #ffffff;
  font-size: 1.275rem;
  padding: 0.5rem 0.5rem;
  border-radius: 50%;
  text-align: center;
`

const FlexDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const AbsoluteLink = styled(Link)`
  position: absolute;
  right: 0;
  top: -1.5rem;
`

const CollectionNft = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  color: white;
  width: 300px;
`

const StyledSVG = styled.svg`
  fill: white;
  opacity: 0.7;

  &:hover {
    opacity: 1;
  }
`
