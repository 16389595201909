import styled from 'styled-components'

export const Flex = styled.div`
  display: flex;
  justify-content: space-between;
`

export const NftNetwork = styled.h2`
  color: whitesmoke;
  font-size: 1.275rem;
  padding: 0.5rem 0.5rem;
  font-weight: 300;
`

export const NftCopiesOwned = styled.h2`
  color: whitesmoke;
  font-size: 1.275rem;
  font-weight: 300;
  padding: 0.5rem 0.5rem;
`

export const NftValue = styled.h1`
  z-index: 1;
  position: absolute;
  top: -3rem;
  left: -1rem;
  background: #000000;
  border: 2px solid #22a90c;
  font-size: 1.275rem;
  padding: 0.5rem 0.5rem;
`

export const StyledVideo = styled.video`
  opacity: 0.9;

  &:hover {
    opacity: 1;
  }
`
